import Logging from "@reservauto/react-shared/Logging";
import StateStoreBase from "@reservauto/react-shared/stores/StateStoreBase";

enum MessageType {
  AnonymousAccessRequest = 1,
  AuthenticatedAccessRequest = 2,
  NewTokenRequest = 3,
  Ready = 4,
  ReloginRequest = 5,
}

interface Message {
  type: MessageType;
}

interface AuthenticatedAccessMessage extends Message {
  token: string;
}

function isAuthenticatedAccessMessage(
  message: Message,
): message is AuthenticatedAccessMessage {
  return (
    message &&
    message.type === MessageType.AuthenticatedAccessRequest &&
    typeof (message as AuthenticatedAccessMessage).token === "string"
  );
}

function isMessage(message: Message): message is Message {
  return message && typeof message.type === "number";
}

class TokenStore extends StateStoreBase<string> {
  private token = "";

  public get(): string {
    return this.token;
  }

  public set(token: string): void {
    this.token = token;
    this.notifySubscribers();
  }
}

class AuthenticationWebViewService {
  private tokenStore = new TokenStore();

  public listenForMessages(onReceived: (token: string) => void): void {
    window.addEventListener("message", (event) => {
      if (!isMessage(event?.data)) {
        Logging.warning("Invalid message format");
        return;
      }

      switch (event.data.type) {
        case MessageType.AnonymousAccessRequest:
          this.tokenStore.set("");
          onReceived("");
          break;

        case MessageType.AuthenticatedAccessRequest:
          if (isAuthenticatedAccessMessage(event.data)) {
            this.tokenStore.set(event.data.token);
            onReceived(event.data.token);
          } else {
            Logging.warning("Invalid authenticated access request");
          }
          break;

        default:
          Logging.warning(`Unknown message type ${event.data.type}`);
          break;
      }
    });
  }

  public notifyReady(numberOfTries = 0): void {
    if (!window.AppChannel) {
      if (numberOfTries < 3) {
        window.setTimeout(() => this.notifyReady(numberOfTries + 1), 1000);
      } else {
        Logging.error("AppChannel is not defined");
      }
      return;
    }

    const message: Message = { type: MessageType.Ready };
    window.AppChannel.postMessage(JSON.stringify(message));
  }

  public requestNewAccessToken(): Promise<void> {
    return new Promise<void>((resolve) => {
      if (!window.AppChannel) {
        Logging.error("AppChannel is not defined");
        resolve();
        return;
      }

      const waitTimeoutSeconds = 60;
      const waitTimeout = window.setTimeout(() => {
        Logging.warning("Token request timed out");
        this.tokenStore.unsubscribe(onReceived);
        resolve();
      }, waitTimeoutSeconds * 1000);

      const onReceived = (): void => {
        window.clearTimeout(waitTimeout);
        this.tokenStore.unsubscribe(onReceived);
        resolve();
      };

      this.tokenStore.subscribe(onReceived);

      const message: Message = { type: MessageType.NewTokenRequest };
      window.AppChannel.postMessage(JSON.stringify(message));
    });
  }

  public requestRelogin(): void {
    if (!window.AppChannel) {
      Logging.error("AppChannel is not defined");
      return;
    }

    const message: Message = { type: MessageType.ReloginRequest };
    window.AppChannel.postMessage(JSON.stringify(message));
  }
}

const authenticationWebViewService = new AuthenticationWebViewService();
export default authenticationWebViewService;
