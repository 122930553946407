import appUrlParams from "../appUrlParams";

const envSettings = import.meta.env;

export default {
  ...envSettings,
  useNewSecurityService:
    envSettings.VITE_NEWSECURITYSERVICEENABLEDFORBRANCHIDS?.split(",")
      .map((id) => parseInt(id))
      ?.includes(appUrlParams.branchId ?? 1),
};
